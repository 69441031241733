/* If you need to add @import statements, do so up here */

@import "jit-refresh.css"; /* triggers frontend rebuilds */

/* Set up Tailwind imports */
@tailwind base;
@tailwind components;

.nav-link {
  @apply text-lg no-underline text-slate-900 hover:text-slate-500 font-semibold;
}

.social-media-links {
  @apply h-6 w-6 hover:text-slate-500;
}

@tailwind utilities;
